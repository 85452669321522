import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  hasErrors: boolean = false;
  lang: any = 'ar';
  constructor() {
    let app: any = localStorage.getItem('app');
    if (app) {
      app = JSON.parse(app);
      this.lang = app.lang ?? 'ar';
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // check if the request has the language header
    if (req.headers.has('Content-Language'))
      return next.handle(req);

    const clonedRequest = req.clone({
      headers: req.headers.set('Content-Language', this.lang) // 'ar' or 'en'
    });
    return next.handle(clonedRequest);
  }
}
