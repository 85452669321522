import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxMatPaginatorIntl } from './ngx-mat-paginator.intl';
import { StickyHeaderDirective } from '../../directives/sticky-header.directive';
import { PageTopDirective } from '../../directives/page-top.directive';
import { IMaskModule } from 'angular-imask';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    StickyHeaderDirective,
    PageTopDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IMaskModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    StickyHeaderDirective,
    PageTopDirective,
    IMaskModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: NgxMatPaginatorIntl,
    },

  ]
})
export class UtilityModule { }
