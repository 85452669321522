import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AppService } from 'src/app/services/app.service';

export interface SnakbarData {
  message: string;
  icon?: string;
}

@Component({
  selector: 'app-snakbar',
  templateUrl: './snakbar.component.html',
  styleUrls: ['./snakbar.component.scss']
})
export class SnakbarComponent implements OnInit {
  lang: any = 'ar';
  dir: any = 'rtl';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnakbarData,
    private appService: AppService) {
    this.lang = this.appService.app.lang;
    this.dir = this.appService.app.lang === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
  }

}
