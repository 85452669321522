import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorModule } from './interceptors';
import { AngularMatrialModule } from './modules/shared/angular-matrial.module';
import { UtilityModule } from './modules/shared/utility.module';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelModule } from 'ngx-pixel';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ProfileComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularMatrialModule,
    UtilityModule,
    InterceptorModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-P9BQSFJR',
    }),
    PixelModule.forRoot({ enabled: true, pixelId: '802564444983987' }),
  ],
  providers: [
    AppService,
    AuthService,
    GoogleTagManagerService,
    { provide: 'googleTagManagerId', useValue: 'GTM-P9BQSFJR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
