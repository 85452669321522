import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  api: string = environment.api + 'profile/';

  constructor(private http: HttpClient, private authService: AuthService) { }

  get(url_name: string) {
    return this.http.get<User>(this.api + url_name, this.authService.authHeaders);
  }

}
