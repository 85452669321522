import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthErrorDialogComponent } from './auth-error-dialog/auth-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Status401Interceptor implements HttpInterceptor {
  hasErrors: boolean = false;
  lang: any = 'ar';
  constructor(private dialog: MatDialog) {
    let app: any = localStorage.getItem('app');
    if (app) {
      app = JSON.parse(app);
      this.lang = app.lang ?? 'ar';
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    let errors = 0;
    return next.handle(req).pipe(
      tap(
        // Operation failed; error is an HttpErrorResponse
        event => event,
        (error: any) => {
          if (error.status === 401 && !this.hasErrors) {
            errors++;
            this.hasErrors = true;
          }
        }
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        if (errors > 0) {
          this.showAuthError();
        }
      })
    );;
  }

  showAuthError = () => {
    const dialogRef = this.dialog.open(AuthErrorDialogComponent, {
      disableClose: true,
      data: {
        title: this.lang === 'en' ? 'Authentication Failed' : 'فشل في الوصول',
        body: this.lang === 'en' ? 'Please login to your account and try again.' : 'من فضلك قم بتسجيل الدخول ثم حاول مرة اخرى.',
        lang: this.lang,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      localStorage.removeItem('user');
      window.location.reload();
    });
  }
}
