import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPageTop]'
})
export class PageTopDirective {
  elm: ElementRef | undefined;
  @HostListener('window:scroll', ['$event']) onWindowScroll(event: Event): void {
    if (event) {
      if (window.scrollY > 300) {
        this.toggleToTop(true);
      } else {
        this.toggleToTop(false);
      }
    }
  }
  constructor(el: ElementRef) {
    this.elm = el;
  }

  private toggleToTop(value: boolean): void {
    if (this.elm) {
      if (value) {
        this.elm.nativeElement.classList.add('sticky');
      } else {
        this.elm.nativeElement.classList.remove('sticky');
      }
    }
  }

}
