import { Component } from '@angular/core';
import { AppService } from './services/app.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Onlycard';
  lang: any = 'ar';
  dir: any = 'rtl';

  constructor(
    public appService: AppService,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {
    appService.applyAppThemeToOverlay();
    appService.applyAppLanguageDirection();
    this.dir = appService.app.lang === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
