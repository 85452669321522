<mat-toolbar class="tool-header" stickyHeader>
  <button mat-icon-button (click)="appService.setAppTheme(appService.app.theme === 'light' ? 'dark' : 'light')">
    <mat-icon>{{ appService.app.theme === 'light' ? 'dark_mode' : 'light_mode'}}</mat-icon>
  </button>
  <span class="spacer"></span>
  <button [cdkCopyToClipboard]="profileURL" (click)="copyLink()" mat-icon-button>
    <mat-icon>share</mat-icon>
  </button>
  <span class="spacer"></span>
  <button mat-icon-button [matMenuTriggerFor]="languageMenu">
    <mat-icon [matBadge]="appService.app.lang === 'ar'? 'ع':'En'" matBadgeColor="accent">language</mat-icon>
  </button>
  <mat-menu #languageMenu="matMenu">
    <button [disabled]="appService.app.lang === 'ar'" (click)="appService.setAppLanguage('ar')" mat-menu-item>
      عربي
    </button>
    <button [disabled]="appService.app.lang === 'en'" (click)="appService.setAppLanguage('en')" mat-menu-item>
      English
    </button>
  </mat-menu>
</mat-toolbar>
<div class="profile-container" (window:resize)="onResize($event)">
  <div *ngIf="!loading && profile && display && display === 'link'" class="profile-wrapper">
    <div class="avatar-image">
      <img [src]="profile.avatar_image ?? '../assets/images/avatar_placeholder.png'" alt="">
    </div>
    <h2 class="profile-name">
      {{ (profile.first_name + ' ' + (profile.last_name !== null ? profile.last_name : '')).trim() }}
    </h2>
    <p *ngIf="profile.bio" class="bio">{{ profile.bio }}</p>
    <!-- <mat-card class="name-card">
    </mat-card> -->
    <div class="links" dir="ltr">
      <div *ngFor="let link of profile.links; let i = index;" class="link-wrapper">
        <a mat-raised-button [style.animation-delay]="((1 + ((i+1)* 1.5))/ 10)+'s'" class="link"
          [href]="sanitize((link.link.base_url ?? '') + link.value)" [target]="isMobile ? '_blank' : link.link.target">
          <img [src]="link.link?.icon_url">
          <span>{{ link.link.display_name }}</span>
        </a>
      </div>
      <div class="link-wrapper" *ngIf="profile.pdf_url">
        <a mat-raised-button [style.animation-delay]="((1 + ((profile.links.length + 1)* 1.5))/ 10)+'s'" class="link"
          [href]="sanitize(profile.pdf_url)" target="_blank">
          <img [src]="'assets/icons/pdf.png'">
          <span>{{ profile.pdf_text }}</span>
        </a>
      </div>
      <div class="link-wrapper" *ngIf="profile.add_contact_url && profile.show_add_contact">
        <a mat-raised-button color="primary"
          [style.animation-delay]="((1.5 + ((profile.links.length + 2)* 1.5))/ 10)+'s'" class="link"
          [href]="sanitize(profile.add_contact_url.replace('storage','analysis/storage').toLowerCase())" target="_blank">
          <img [src]="'assets/icons/add_contact_dark.png'">
          <span>{{ 'Add To Contacts' | translate }}</span>
        </a>
      </div>
    </div>
    <div class="other-icons">
      <a mat-icon-button *ngIf="profile.phone && profile.show_phone" [style.animation]="'fadeIn 0.3s ease-in both'"
        [style.animation-delay]="((2 + ((profile.links.length + 2)* 1.5)) / 10) + 's'" [href]="'tel:'+profile.phone"
        target="_top">
        <mat-icon>phone</mat-icon>
      </a>
      <a mat-icon-button *ngIf="profile.email && profile.show_email" [style.animation]="'fadeIn 0.3s ease-in both'"
        [style.animation-delay]="((2.5 + ((profile.links.length + 2)* 1.5)) / 10) + 's'"
        [href]="'mailto:'+profile.email+'?subject=&body='"
        target="_blank">
        <mat-icon>email</mat-icon>
      </a>
      <a mat-icon-button *ngIf="profile.location && profile.show_location"
        [style.animation]="'fadeIn 0.3s ease-in both'"
        [style.animation-delay]="((3 + ((profile.links.length + 2)* 1.5)) / 10) + 's'" [href]="profile.location"
        target="_blank">
        <mat-icon>location_pin</mat-icon>
      </a>
      <a mat-icon-button *ngIf="profile.website && profile.show_website" [style.animation]="'fadeIn 0.3s ease-in both'"
        [style.animation-delay]="((3.5 + ((profile.links.length + 2)* 1.5)) / 10) + 's'" [href]="profile.website"
        target="_blank">
        <mat-icon>language</mat-icon>
      </a>
    </div>
    <br><br>
    <button mat-stroked-button *ngIf="profile.display !== 'link'" [style.animation]="'fadeIn 0.3s ease-in both'"
      [style.animation-delay]="((4 + (profile.links.length* 1.5)) / 10) + 's'" (click)="display = profile.display">
      <mat-icon>badge</mat-icon>
      {{ 'Show Card' | translate }}
    </button>
  </div>
  <div *ngIf="!loading && profile && ['card','custom'].includes(display && display)" dir="ltr" class="card-wrapper">
    <p *ngIf="sMD">{{ 'Click the card to show details' | translate }}</p>
    <div class="flip-card" [class.flip]="flip" (click)="flip = !flip">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img
            [src]="(profile.front_image !== null && display && display === 'custom') ? profile.front_image : (profile.template !== null ? profile.template.front_image:'../assets/images/front_image.jpg')"
            alt="Card Front">
          <div class="content" *ngIf="display && display === 'card'">
            <h1>{{ profile.business ?? (profile.first_name + ' ' + (profile.last_name !== null ? profile.last_name :
              '')).trim() }}</h1>
          </div>
          <div class="app-logo" *ngIf="display && display === 'card'">
            <img src="../../assets/images/logo.png" alt="Onlycard">
            <p>{{ 'Create your digital info' | translate}}</p>
          </div>
        </div>
        <div class="flip-card-back">
          <img
            [src]="(profile.back_image !== null && display && display === 'custom') ? profile.back_image : (profile.template !== null ? profile.template.back_image:'../assets/images/back_image.jpg')"
            alt="Card Back">
          <div class="content" *ngIf="display && display === 'card'">
            <div class="person-space">
              <h1>{{ (profile.first_name + ' ' + (profile.last_name !== null ? profile.last_name : '')).trim() }}</h1>
              <h2 *ngIf="profile.position">{{ profile.position }}</h2>
            </div>
            <div class="person-links">
              <ul>
                <li *ngIf="profile.phone">
                  <a [href]="'tel:' + profile.phone" mat-button>
                    <mat-icon>phone</mat-icon>
                    {{ profile.phone }}
                  </a>
                </li>
                <li *ngIf="profile.email">
                  <a [href]="'mailto:' + profile.email" mat-button>
                    <mat-icon>email</mat-icon>
                    {{ profile.email }}
                  </a>
                </li>
                <li *ngIf="profile.website && (!profile.country && !profile.city)">
                  <a [href]="'https://'+profile.website" target="_blank" mat-button>
                    <mat-icon>language</mat-icon>
                    {{ profile.website }}
                  </a>
                </li>
                <li *ngIf="profile.country && profile.city">
                  <a *ngIf="profile.location" [href]="profile.location" target="_blank" mat-button>
                    <mat-icon>place</mat-icon>
                    {{ profile.city+' - '+profile.country }}
                  </a>
                  <button mat-button *ngIf="!profile.location">
                    <mat-icon>place</mat-icon>
                    {{ profile.city+' - '+profile.country }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button mat-stroked-button (click)="display = 'link'">
      <mat-icon>link</mat-icon>
      {{ 'Social Links Info' | translate }}
    </button>
  </div>
  <div *ngIf="loading || !profile" class="loading">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  <div *ngIf="!loading && profile && !profile.hide_logo" [style.animation]="'fadeIn 0.3s ease-in both'"
    [style.animation-delay]="(profile?.display === 'link' ? ((4.5 + (profile?.phone ? 3 : 0)+ (profile?.location ? 3 : 0) + profile.links.length* 1.5) / 10) : '0.9') + 's'"
    class="profile-footer">
    <a href="https://onlycardsa.com" mat-button><img [class.darken]="appService.app.theme === 'light'"
        src="../../assets/images/logo.png" alt="Onlycard Logo">
      <mat-icon class="outlink">north_east</mat-icon>
    </a>
    <!-- <a href="https://wa.me/+966505806602" mat-button><img [class.darken]="appService.app.theme === 'light'"
        src="../../assets/images/logo.png" alt="Onlycard Logo">
      <mat-icon class="outlink">north_east</mat-icon>
    </a> -->
    <!-- <h1>Onlycard</h1> -->
    <!-- <p [class.darken-font]="appService.app.theme === 'light'">{{ 'Create your digital info' | translate}}</p> -->
  </div>
</div>
