import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface App {
  theme: string;
  lang: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private overlay: OverlayContainer, private translate: TranslateService,
    private router: Router) { }

  get app(): App {
    let app: any = localStorage.getItem('app');
    if (app) {
      app = JSON.parse(app)
    } else {
      app = { theme: 'dark', lang: 'ar' }
      this.storeApp(app);
    }
    return app;
  }

  get dir() {
    return this.app.lang === 'ar' ? 'rtl' : 'ltr';
  }

  setAppLanguage(lang: string): void {
    let app = this.app;
    app = { ...app, lang };
    this.storeApp(app);
    this.applyAppLanguageDirection();
    this.refreshAfterChangeAppLanguage();
    window.location.reload();
  }

  setAppTheme(theme: string) {
    let app = this.app;
    app = { ...app, theme };
    this.storeApp(app);
    this.applyAppThemeToOverlay();
  }

  applyAppThemeToOverlay() {
    let body = document.getElementsByTagName('body')[0];
    if (this.app.theme === 'dark') {
      this.overlay.getContainerElement().classList.add('dark-mode');
      body.classList.add('dark-mode');
    } else {
      this.overlay.getContainerElement().classList.remove('dark-mode');
      body.classList.remove('dark-mode');
    }
  }

  applyAppLanguageDirection() {
    let html = document.getElementsByTagName('html')[0];
    if (this.app.lang === 'en') {
      html.setAttribute('dir', 'ltr');
    } else {
      html.setAttribute('dir', 'rtl');
    }
    let lang = this.app.lang;
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
  }

  refreshAfterChangeAppLanguage() {
    const prev = this.router.url;
    this.router.navigate(['/']).then(data => {
      this.router.navigate([prev]);
    });
  }

  storeApp(app: App) {
    localStorage.setItem('app', JSON.stringify(app));
  }
}
