import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor() { }

  loadGoogleAnalytics(trackingID: string, profile: any): void {

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-XHHQWLJ521\');gtag(\'config\', \'${trackingID}\');`;


    // push custom event with current page url
    let gaScript3 = document.createElement('script');
    gaScript3.innerText = `gtag('event', 'page_view', {
    'page_title' : '${profile?.first_name + ' ' + profile?.last_name}'s Profile',
    'page_location': '${window.location}',
    'page_path': '${window.location}'
  });`;

    var head = document.getElementsByTagName('head')[0];
    var body = document.getElementsByTagName('body')[0];

    head.appendChild(gaScript);
    body.appendChild(gaScript2);
    body.appendChild(gaScript3);
  }

}
