// import { AfterViewInit } from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { Card } from '../models/card';
import { SnakbarComponent } from '../modules/admin/clients/snakbar/snakbar.component';
import { AppService } from '../services/app.service';
import { ProfileService } from '../services/profile.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RetargetingService } from '../services/retargeting.service';
import { PixelService } from 'ngx-pixel';
import { IntegrationService } from '../services/integration.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  profile?: any;
  lang: any = 'ar';
  loading: boolean = false;
  sMD: boolean = false;
  isMobile: boolean = false;
  profileURL: any;
  display: any;
  flip: boolean = false;
  // userLang: any = navigator.language && navigator.language.includes('en') ? 'en' : 'ar';
  constructor(
    public appService: AppService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    private router: Router,
    private http: HttpClient,
    private profileService: ProfileService,
    private _integrationService: IntegrationService,
    private retargetingService: RetargetingService,
    private pixelService: PixelService,
  ) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.isMobile = true;
    }
    this.lang = this.appService.app.lang;
    this.profileURL = window.location;
  }

  onResize(event: any) {
    this.sMD = event.target.innerWidth <= 425;
  }

  ngOnInit() {
    this.sMD = window.innerWidth <= 425;
    this.loading = true;
    this.route.paramMap.subscribe((route: any) => {
      if (route.params) {
        let urlName = route.params.url_name;
        this.profileService
          .get(urlName)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res: any) => {
              this.profile = res;
              if (this.profile.link_add_contact) {
                const fileURL = this.profile.add_contact_url.toLowerCase();
                setTimeout(() => {
                  window.location.href = fileURL;
                }, 1000);
              }
              // load google analytics
              this.retargetingService.loadGoogleAnalytics(this.profile);
              // load tiktok pixel
              this.retargetingService.loadTikTokPixel(this.profile);
              // load snapchat pixel
              this.retargetingService.loadSnapchatPixel(this.profile);
              // load facebook pixel
              this.retargetingService.loadFacebookPixel(this.profile);
              // load linkedin pixel
              this.retargetingService.loadLinkedIn(this.profile);
              // load twitter pixel
              this.retargetingService.loadTwitterX(this.profile);
              this.display = res.display;
              this.loading = false;
            },
            (err) => {
              if (err.status === 404) {
                this.router.navigate(['page-not-found']);
              }
            }
          );
      }
    });
  }

  ngOnDestroy() {
    this.pixelService.remove();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  saveDownloadedFile(event: any, filename: any) {
    // let dataType = event.body.type;
    // let binaryData: any = [];
    // binaryData.push(event.body);
    // let downloadLink = document.createElement('a');
    // downloadLink.href = window.URL.createObjectURL(
    //   new Blob(binaryData, { type: dataType })
    // );
    // downloadLink.setAttribute('download', filename);
    // downloadLink.setAttribute('id', 'downloadA');
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    if (this.profile.link_add_contact) {
      const fileURL = this.profile.add_contact_url.toLowerCase();
      window.location.href = fileURL;
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  copyLink() {
    if (navigator.share) {
      navigator
        .share({
          title: (
            this.profile?.first_name +
            ' ' +
            this.profile?.last_name +
            ' | Onlycard'
          ).trim(),
          url: this.profileURL,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      return this.openSnackBar(
        this.lang === 'en' ? 'Link copied' : 'تم نسخ الرابط',
        'content_copy'
      );
    }
  }

  openSnackBar(message: string, icon: any = undefined) {
    this.snackBar.openFromComponent(SnakbarComponent, {
      data: {
        message: this.translate.instant(message),
        icon: icon,
      },
      duration: 3000,
    });
  }
}
