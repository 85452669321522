import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[stickyHeader]'
})
export class StickyHeaderDirective implements AfterViewInit {

  elm: ElementRef | undefined;

  elmOffsetTop = 0;

  constructor(el: ElementRef) {
    this.elm = el;
    this.elm.nativeElement.classList.add('sticky');
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(event: Event): void {
    if (event) {
      if (window.scrollY === 0) {
        this.setElementOffsetTop();
      }
      this.toggleSticky(window.pageYOffset);
    }
  }

  ngAfterViewInit() {
    this.setElementOffsetTop();
  }

  setElementOffsetTop() {
    this.elmOffsetTop = this.elm?.nativeElement.getBoundingClientRect().top;
  }

  private toggleSticky(offset: Number) {
    if (this.elm) {
      if (offset > this.elmOffsetTop) {
        this.elm.nativeElement.classList.remove('sticky');
      } else {
        this.elm.nativeElement.classList.add('sticky');
      }
    }
  }
}
