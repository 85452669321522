import { Injectable } from '@angular/core';
import { PixelService } from 'ngx-pixel';

@Injectable({
  providedIn: 'root'
})
export class RetargetingService {

  constructor(
    private pixel: PixelService
  ) { }

  loadGoogleAnalytics(profile: any): void {
    let trackingID = profile.retargeting_config.find((x: any) => x.platform === 'Google Analytics')?.tracking_code;
    if (!trackingID) return;
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-XHHQWLJ521\');gtag(\'config\', \'${trackingID}\');`;


    // push custom event with current page url
    let gaScript3 = document.createElement('script');
    gaScript3.innerText = `gtag('event', 'page_view', {
    'page_title' : '${profile?.first_name + ' ' + profile?.last_name}'s Profile',
    'page_location': '${window.location}',
    'page_path': '${window.location}'
  });`;

    var head = document.getElementsByTagName('head')[0];
    var body = document.getElementsByTagName('body')[0];

    head.appendChild(gaScript);
    body.appendChild(gaScript2);
    body.appendChild(gaScript3);
  }

  loadFacebookPixel(profile: any): void {
    let pixelID = profile.retargeting_config.find((x: any) => x.platform === 'Pixel')?.tracking_code;
    if (!pixelID) return;
    this.pixel.remove();
    this.pixel.initialize(pixelID);
    this.pixel.trackCustom('OnlycardProfileView',
      {
        test_event_code: 'TEST68421',
        page: profile?.first_name + ' ' + profile?.last_name + ' | Onlycard',
        card_no: profile?.request_no,
        url_name: profile?.user?.url_name,
      }
    );
  }

  loadTikTokPixel(profile: any): void {
    let pixelID = profile.retargeting_config.find((x: any) => x.platform === 'TikTok')?.tracking_code;
    if (!pixelID) return;
    let tiktokScript = document.createElement('script');
    tiktokScript.setAttribute('async', 'true');
    tiktokScript.setAttribute('src', `https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=${pixelID}&lib=0.1.0&v=0.1.0&ts=${Date.now()}`);

    let tiktokScript2 = document.createElement('script');
    tiktokScript2.innerText = `var tiktokPixel = new TiktokPixel(${pixelID});tiktokPixel.track('PageView', {page_name: '${profile?.first_name + ' ' + profile?.last_name}'s Profile', url: '${window.location}'})`;

    var head = document.getElementsByTagName('head')[0];

    head.appendChild(tiktokScript);
    head.appendChild(tiktokScript2);
  }

  loadSnapchatPixel(profile: any): void {
    let pixelID = profile.retargeting_config.find((x: any) => x.platform === 'Snapchat')?.tracking_code;
    if (!pixelID) return;
    let snapScript = document.createElement('script');
    snapScript.setAttribute('async', 'true');
    snapScript.setAttribute('src', `https://sc-static.net/scevent.min.js`);

    let snapScript2 = document.createElement('script');
    snapScript2.innerText = `window.snaptr = function () {var snaptr = snaptr || {};snaptr.config = snaptr.config || {};snaptr.config.prod_id = '${pixelID}';var sc = document.createElement('script');sc.async = true;sc.src = "https://sc-static.net/scevent.min.js";var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(sc, s);};snaptr();snaptr('track', 'PAGE_VIEW', {page_name: '${profile?.first_name + ' ' + profile?.last_name}'s Profile', url: '${window.location}'});`;

    var head = document.getElementsByTagName('head')[0];

    head.appendChild(snapScript);
    head.appendChild(snapScript2);
  }

  loadTwitterX(profile: any): void {
    let pixelID = profile.retargeting_config.find((x: any) => x.platform === 'Twitter (X)')?.tracking_code;
    if (!pixelID) return;
    let twitterScript = document.createElement('script');
    twitterScript.setAttribute('async', 'true');
    twitterScript.setAttribute('src', `https://platform.twitter.com/oct.js`);

    let twitterScript2 = document.createElement('script');
    twitterScript2.innerText = `twttr.conversion.trackPid('${pixelID}', { tw_sale_amount: 0, tw_order_quantity: 0 });`;

    var head = document.getElementsByTagName('head')[0];

    head.appendChild(twitterScript);
    head.appendChild(twitterScript2);
  }

  loadLinkedIn(profile: any): void {
    let pixelID = profile.retargeting_config.find((x: any) => x.platform === 'LinkedIn')?.tracking_code;
    if (!pixelID) return;
    let linkedinScript = document.createElement('script');
    linkedinScript.setAttribute('async', 'true');
    linkedinScript.setAttribute('src', `https://snap.licdn.com/li.lms-analytics/insight.min.js`);

    let linkedinScript2 = document.createElement('script');
    linkedinScript2.innerText = `linkedin_insight_tag.init('${pixelID}');linkedin_insight_tag.track('pageView');`;

    var head = document.getElementsByTagName('head')[0];

    head.appendChild(linkedinScript);
    head.appendChild(linkedinScript2);
  }

}
