import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Status401Interceptor } from './status401.interceptor';
import { AngularMatrialModule } from '../modules/shared/angular-matrial.module';
import { AuthErrorDialogComponent } from './auth-error-dialog/auth-error-dialog.component';
import { LanguageInterceptor } from './langauge.interceptor';

@NgModule({
  declarations: [AuthErrorDialogComponent],
  imports: [
    AngularMatrialModule,
  ],
  providers: [
    LanguageInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    Status401Interceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Status401Interceptor,
      multi: true
    },
  ]
})
export class InterceptorModule {
}
