import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';

export interface Credentials {
  email?: string | null;
  phone?: string | null;
  password?: string;
  new_password?: string;
  new_password_confirmation?: string;
  password_otp?: string;
}

export interface UserData {
  id: number;
  name: string;
  email: string;
  is_admin: boolean;
}

export interface AuthData {
  user: UserData;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api: string = environment.api + 'auth/';
  redirect = new BehaviorSubject<String>('/manage');
  constructor(private http: HttpClient,
    private appService: AppService) { }

  requestAuth(data: Credentials) {
    return this.http.post(this.api + 'request', data);
  }

  resetOtp(data: Credentials) {
    return this.http.post(this.api + 'reset-otp', data);
  }

  resetPassword(data: Credentials) {
    return this.http.post(this.api + 'reset-password', data);
  }

  login(credentials: Credentials) {
    return this.http.post(this.api + 'login', credentials);
  }

  handleLogin(authData: AuthData) {
    return this.storeUser(authData);
  }

  storeUser(user: AuthData) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  isLoggedIn(): boolean {
    return this.authData && this.authData.token ? true : false;
  }

  isAdmin(): boolean {
    return this.authData && this.authData.user.is_admin ? true : false;
  }

  isSuperAdmin(): boolean {
    return this.authData && this.authData.user.is_super_admin ? true : false;
  }

  logout() {
    localStorage.removeItem('user');
  }

  get authData() {
    let authData = localStorage.getItem('user');
    if (authData) {
      return JSON.parse(authData);
    } else {
      return {};
    }
  }

  get authHeaders(): any {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.authData.token}`,
        'Content-Language': this.appService.app.lang ?? 'ar',
        'timeout': '60000'
      })
    };
  }

  get authHeadersForm(): any {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.authData.token}`,
        'Content-Language': this.appService.app.lang ?? 'ar',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

}
